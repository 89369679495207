//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-944:_9792,_1544,_4448,_368,_2720,_9196,_1201,_268;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-944')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-944', "_9792,_1544,_4448,_368,_2720,_9196,_1201,_268");
        }
      }catch (ex) {
        console.error(ex);
      }